export default (() => {
    const scrollTo = document.querySelectorAll( '.scroll-to' )

    if ( !scrollTo.length ) {
        return
    }

    scrollTo.forEach(elem => {
        function scrollToElement(event) {
            const hash = event.target.hash.substr(1)
            const target = document.getElementById( hash )

            if ( target !== null ) {
                event.preventDefault()

                target.scrollIntoView({
                    behavior: 'smooth'
                })

                document.getElementById('hamburger__toggle').checked = false
            }
        }

        elem.addEventListener( 'click', function ( event ) {
            scrollToElement(event)
        }, false)

        elem.addEventListener( 'keypress', function ( event ) {
            if (e.key === 'Enter') {
                scrollToElement(event)
            }
        }, false)
    })
})()
