class Appointment {
    constructor(element) {
        this.appointment = element
        this.iframe = this.appointment.querySelector('#tucalendi_iframe_root')
        this.preview = this.appointment.querySelector('.appointment__preview')
        this.button = this.appointment.querySelector('.appointment__button')

        if (!document.cookie.split(';').some((item) => item.includes('tucalendi=true'))) {
            this.init()
        } else {
            this.addScript()
        }
    }

    init() {
        this.addEventListeners()
    }

    addEventListeners() {
        if (this.button == null) return

        this.button.addEventListener('click', (event) => this.buttonClick(event))
        this.button.addEventListener('keypress', function (event) {
            if (e.key === 'Enter') {
                this.buttonClick(event)
            }
        }, false)
    }

    buttonClick() {
        event.preventDefault()
        this.removePreview()
        this.addScript()
        this.setCookie()
    }

    addLoader() {
        let loader = document.createElement("div")
        loader.classList.add('loader__wrap')
        loader.innerHTML = '<div class="loader"></div>'

        this.preview.classList.add('loading')
        this.preview.appendChild(loader)
    }

    removePreview() {
        MutationObserver = window.MutationObserver || window.WebKitMutationObserver

        var observer = new MutationObserver(function(mutations, observer) {
            let target = mutations[0].target

            if (target.offsetHeight > 1) {
                target.previousElementSibling.classList.add('d-none')

                observer.disconnect()
            }
        })

        observer.observe(this.iframe, {
            subtree: true,
            attributes: true
        })
    }

    addScript() {
        if (this.iframe == null) return

        let script = document.createElement("script")

        script.type = 'text/javascript'
        script.src = 'https://widgets.tucalendi.com/assets/iframewidget_cf.js'
        this.iframe.parentNode.appendChild(script)

        this.addLoader()
        this.removePreview()
    }

    setCookie() {
        document.cookie = 'tucalendi=true'
    }
}

const appointment = document.querySelectorAll('.appointment')

appointment.forEach(appointment => {
    new Appointment(appointment)
})
