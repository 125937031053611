import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'

class MasonryLayout {
    constructor(element) {
        this.masonryWrapper = element
        this.msnry

        this.smallViewport = window.matchMedia('(max-width: 767px)')

        this.init()
    }

    init() {
        this.masonry()
    }

    masonry() {
        const options = {
            itemSelector: '.masonry__item',
            columnWidth: '.masonry__sizer',
            horizontalOrder: false,
            percentPosition: true,
        }

        imagesLoaded(this.masonryWrapper, () => {
            this.msnry = new Masonry(this.masonryWrapper, options)

            this.masonryWrapper.classList.add('masonry-initialized')
        })
    }
}

const masonry = document.querySelectorAll('.masonry__wrapper')

masonry.forEach(masonry => {
    new MasonryLayout(masonry)
})
