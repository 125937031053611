import lottie from 'lottie-web/build/player/lottie_light'

class Animation {
    constructor(el) {
        this.anim = el

        this.smallViewport = window.matchMedia('(max-width: 767px)')

        this.init()
    }

    init() {
        this.addAnimation()
    }

    addAnimation() {
        if (this.anim.dataset.src == null) return

        lottie.loadAnimation({
            container: this.anim,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: this.anim.dataset.src,
        })
    }
}

const anim = document.querySelectorAll('.animation')

anim.forEach(anim => {
    new Animation(anim)
})
