import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';

Swiper.use([Autoplay])
class Stage {
    constructor(element) {
        this.stage = element
        this.slider = this.stage.querySelector('.swiper')
        this.smallViewport = window.matchMedia('(max-width: 767px)')

        this.init()
    }

    init() {
        this.sliderHandler(this)
    }

    sliderHandler(root) {
        var stageSwiper = new Swiper(root.slider, {
            speed: 750,
            autoHeight: true,
            slidesPerView: 1.3,
            spaceBetween: 24,
            slideToClickedSlide: true,
            roundLengths: true,
            loop: false,
            autoplay: {
                delay: 4000,
            },
            navigation: {
                nextEl: ".centermode-button-next",
                prevEl: ".centermode-button-prev"
            },
        })
    }
}

const stage = document.querySelectorAll('.stage')

stage.forEach(stage => {
    new Stage(stage)
})
