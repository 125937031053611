import Swiper from 'swiper'
import { Pagination } from 'swiper/modules';
import lightGallery from 'lightgallery';

Swiper.use([Pagination])

class Carousel {
    constructor(element) {
        this.carouselWrapper = element

        this.slider = this.carouselWrapper.querySelector('.swiper')
        this.sliderWrapper = this.slider.querySelector('.swiper-wrapper')
        this.swiper

        this.smallViewport = window.matchMedia('(max-width: 767px)')

        this.init()
    }

    init() {
        this.carousel(this)
    }

    carousel(root) {
        this.swiper = new Swiper(this.slider, {
            slidesPerView: 'auto',
            initialSlide: 0,
            speed: 1000,
            spaceBetween: 24,
            loop: true,
            slidesPerView: 1.5,
            centeredSlides: true,
            pagination: {
                el: this.slider.nextElementSibling,
                clickable: true,
            },
            on: {
                init: function () {
                    const lg = lightGallery(root.sliderWrapper, {
                        licenseKey: 'purchase-in-progress',
                        animateThumb: false,
                        zoomFromOrigin: false,
                        allowMediaOverlap: true,
                        toggleThumb: true,
                        download: false,
                    })

                    // root.sliderWrapper.addEventListener('lgBeforeClose', () => {
                    //     console.log(lg.index)
                    //     root.swiper.slideToLoop(lg.index, 0)
                    // });
                },
            }
        })
    }
}

const carousel = document.querySelectorAll('.carousel__wrapper')

carousel.forEach(carousel => {
    new Carousel(carousel)
})
